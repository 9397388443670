module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es","ca","eu","gl"],"defaultLanguage":"es","redirect":false,"siteUrl":"https://hackasom.somenergia.coop/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"fallbackLng":"es"},"pages":[{"matchPath":"/aviso-legal","getLanguageFromPath":true,"languages":["es"]},{"matchPath":"/ca/avis-legal","getLanguageFromPath":true,"languages":["ca"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
